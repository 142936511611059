<template>
  <div class="container">
    <My-Breadcrumb :breadcrumbs="breadcrumbs" />
    <div class="mainBox">
      <div class="control_box">
        <Search-bar :inputs="inputs" @search="search" @reset="search" />
        <div class="control_box_bottom">
          <a-button type="primary" icon="plus" @click="showBatch()" style="margin-right: 10px">添加公告</a-button>
        </div>
      </div>
      <!-- 内容区域 -->
      <div class="show_box">
        <a-table :columns="columns" :data-source="tableData" :loading="loading" :rowKey="(tableData) => tableData.id"
          :pagination="pagination" @change="handleTableChange">
          <template slot="action" slot-scope="text, record">
            <a-button type="link" @click="() => edit(record.id)" >编辑</a-button>
            <a-button type="link" @click="() => remove(record.id)" class="e-space" >删除</a-button>

          </template>
          <template slot="is_valid" slot-scope="text, record">
            <a-switch :checked="record.is_valid == 1" :loading="disableLoading" @change="() => changeStatus(record)" />
          </template>
        </a-table>
      </div>
    </div>
    <!-- 新增/修改 -->
    <a-modal v-model="editModalVisible" :title="editModalTitle" cancelText="取消" :width="1000" @ok="preSave()"
      okText="保存">
      <a-form-model ref="batchForm" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }" :model="tmp"
        :rules="formRules">
        <a-form-model-item label="公告名称" prop="title">
          <a-input v-model="tmp.title" />
        </a-form-model-item>
        <a-form-model-item label="排序" prop="order_weight">
          <a-input v-model="tmp.order_weight" />
        </a-form-model-item>
        <a-form-model-item label="状态">
          <a-switch :checked="tmp.is_valid == 1" @change="() => changeTmpStatus()" />
        </a-form-model-item>
        <a-form-model-item label="内容">
          <vue-editor v-model="tmp.content" useCustomImageHandler @image-added="handleImageAdded" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
//引入接口api
import {
  notices,
  disableNotice,
  addNotice,
  editNotice,
  getEditNotice,
  noticePicUpload,
  removeNotice,
} from "@/request/api";
//引入封装的组件
import SearchBar from "@/components/common/SearchBar.vue";
import MyBreadcrumb from "@/components/common/MyBreadcrumb.vue";
//引入工具函数
import util from "@/utils/util";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    SearchBar,
    MyBreadcrumb,
  },
  data () {
    return {
      //传给MyBreadcrumb的参数
      breadcrumbs: [
        {
          key: 0,
          title: "系统设置",
        },
        {
          key: 1,
          title: "公告管理",
        },
      ],

      //传给searchBar公共搜索组件的数据
      inputs: [
        {
          placeholder: "请输入公告名称",
          model: "title",
          width: 300,
        },
      ],
      //请求参数
      queryParam: {
        page: 1,
        title: "",
      },
      tableData: [], //从接口请求回来并在js部分整理好的表格数据
      dataForExport: [], //不分页的数据
      loading: false, //table加载状态

      // 表格的列配置
      columns: [
        {
          title: "标题",
          key: "title",
          dataIndex: "title",
        },
        {
          title: "排序权重",
          key: "order_weight",
          dataIndex: "order_weight",
        },
        {
          title: "创建时间",
          key: "created_at",
          dataIndex: "created_at",
        },
        {
          title: "状态",
          key: "is_valid",
          scopedSlots: { customRender: "is_valid" },
        },
        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ],

      //页配置
      pagination: {
        total: 0,
        pageSize: 15,
        current: 1,
      },
      disableLoading: false,
      editModalVisible: false,
      editModalTitle: "新增公告",
      tmp: {
        id: "",
        title: "",
        content: "",
        order_weight: "",
        is_valid: 1,
      },
      formRules: {
        title: [{ required: true, message: "请输入公告名称", trigger: "blur" }],
        order_weight: [
          { required: true, message: "请输入排序权重", trigger: "blur" },
          { pattern: /^[0-9]+$/, message: "排序权重格式错误", trigger: "blur" },
        ],
      },
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    //刷新和搜索一样的方法
    search (val) {
      this.queryParam = {
        page: 1,
        title: val.input.title ? val.input.title : "",
      };
      this.pagination.current = 1;
      this.getTableData();
    },

    //获取表格数据
    getTableData () {
      this.loading = true;
      this.queryParam.no_paging = 0;
      notices(this.queryParam).then((res) => {
        //处理页码
        const pagination = { ...this.pagination };
        pagination.total = res.data.total;
        this.pagination = pagination;
        if (res.data.data.length == 0) {
          this.loading = false;
          this.tableData = [];
          return;
        }
        this.tableData = res.data.data;
        this.loading = false;
      });
    },
    edit (id) {
      getEditNotice(id)
        .then((res) => {
          this.tmp = res.data;
          this.editModalVisible = true;
          this.editModalTitle = "修改公告";
        })
        .catch((err) => {
          let { errors } = err.data;
          for (let i in errors) {
            this.$message.error(errors[i][0]);
          }
        });
    },
    changeStatus (row) {
      this.disableLoading = true;
      let id = row.id;
      let p = {
        disable: row.is_valid == 1 ? 0 : 1,
      };
      disableNotice(id, p)
        .then((res) => {})
        .catch((err) => {
          this.disableLoading = false;
          if (err.status == 201) {
            this.$message.success("操作成功");
            row.is_valid = p.disable;
          } else {
            let { errors } = err.data;
            for (let i in errors) {
              this.$message.error(errors[i][0]);
            }
          }
        });
    },
    changeTmpStatus () {
      this.tmp.is_valid = this.tmp.is_valid == 1 ? 0 : 1;
    },
    //分页
    handleTableChange (pagination) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.queryParam.page = pagination.current;
      this.getTableData();
    },

    //格式转换
    formatJson (filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },

    //打开个性化批量充值导入模态框
    showBatch () {
      this.editModalVisible = true;
      this.editModalTitle = "添加公告";
      this.resetTmp();
    },
    handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
      var formData = new FormData();
      formData.append("notice_img", file);
      noticePicUpload(formData)
        .then((res) => {})
        .catch((err) => {
          this.disableLoading = false;
          if (err.status == 201) {
            this.$message.success("图片上传成功");
            Editor.insertEmbed(cursorLocation, "image", err.data.path);
            resetUploader();
          } else {
            let { errors } = err.data;
            for (let i in errors) {
              this.$message.error(errors[i][0]);
            }
          }
        });
    },
    remove: function (id) {
      let th = this;
      this.$confirm({
        title: "操作提示",
        content: "删除之后不可恢复，您确认删除该数据？",
        onOk () {
          removeNotice(id)
            .then((res) => {})
            .catch((err) => {
              if (err.status == 201) {
                th.$message.success("操作成功");
                th.getTableData();
              } else {
                let { errors } = err.data;
                for (let i in errors) {
                  th.$message.error(errors[i][0]);
                }
              }
            });
        },
        onCancel () { },
      });
    },
    preSave () {
      let th = this;
      this.$refs["batchForm"].validate((valid) => {
        if (valid) {
          th.saveNotice();
        }
      });
    },
    saveNotice () {
      this.tmp.disable = this.tmp.is_valid;
      this.tmp.sort = this.tmp.order_weight;
      if (!this.tmp.id) {
        addNotice(this.tmp)
          .then((res) => {})
          .catch((err) => {
            if (err.status == 201) {
              this.$message.success("操作成功");
              this.getTableData();
              this.editModalVisible = false;
            } else {
              let { errors } = err.data;
              for (let i in errors) {
                this.$message.error(errors[i][0]);
              }
            }
          });
      } else {
        editNotice(this.tmp.id, this.tmp)
          .then((res) => {})
          .catch((err) => {
            if (err.status == 201) {
              this.$message.success("操作成功");
              this.getTableData();
              this.editModalVisible = false;
            } else {
              let { errors } = err.data;
              for (let i in errors) {
                this.$message.error(errors[i][0]);
              }
            }
          });
      }
    },
    resetTmp () {
      this.tmp.id = "";
      this.tmp.title = "";
      this.tmp.content = "";
      this.tmp.order_weight = "";
      this.tmp.is_valid = 1;
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.getTableData();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.mainBox {
  background-color: #f0f2f5;
  .control_box {
    background-color: white;
    padding: 20px;
    .control_box_bottom {
      margin-top: 20px;
    }
  }
  .show_box {
    width: 100%;
    padding-top: 10px;
  }
}
.e-space {
  margin-left: 10px;
}
</style>